<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item label="入库时间">
        <!-- <el-input v-model="dataForm.key" placeholder="参数名" clearable></el-input> -->
        <el-date-picker v-model="dataForm.dates" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item label="客户名称">
        <el-input v-model="dataForm.customerName" placeholder="客户名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="设备名称">
        <el-input v-model="dataForm.deviceName" placeholder="设备名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="SN">
        <el-input v-model="dataForm.deviceCode" placeholder="识别码" clearable></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="dataForm.remark" placeholder="备注" clearable></el-input>
      </el-form-item>
      <el-form-item label="登录地点">
        <el-input v-model="dataForm.lastAddr" placeholder="登录地点" clearable></el-input>
      </el-form-item>
      <el-form-item label="登录手机">
        <el-input v-model="dataForm.lastPhone" placeholder="登录手机" clearable></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-select v-model="dataForm.state" placeholder="请选择状态" clearable>
          <el-option label="未激活" :value="0"></el-option>
          <el-option label="已激活" :value="1"></el-option>
          <el-option label="黑名单" :value="-1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="refresh()">查询</el-button>
        <el-button v-if="isAuth('wxs:wxsstock:save')" type="primary" @click="addOrUpdateHandle()">手动入库</el-button>
        <!-- <el-button v-if="isAuth('wxs:wxsstock:delete')" type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button> -->
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <!-- <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="">
      </el-table-column> -->
      <el-table-column
        prop="customerName"
        header-align="center"
        align="center"
        label="客户名称">
      </el-table-column>
      <el-table-column
        prop="deviceName"
        header-align="center"
        align="center"
        label="设备名称">
      </el-table-column>
      <el-table-column
        prop="deviceCode"
        header-align="center"
        align="center"
        label="设备识别码">
      </el-table-column>
      <el-table-column
        prop="state"
        header-align="center"
        align="center"
        label="状态" width="80">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state === 0" size="small" type="warning">未激活</el-tag>
          <el-tag v-else-if="scope.row.state === 1" size="small" type="success">已激活</el-tag>
          <el-tag v-else-if="scope.row.state === -1" size="small" type="danger">黑名单</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="createtime"
        header-align="center"
        align="center"
        label="入库时间">
      </el-table-column>
      <el-table-column
        prop="remark"
        header-align="center"
        align="center"
        label="备注">
      </el-table-column>
      <el-table-column
        prop="lastAddr"
        header-align="center"
        align="center"
        label="最后登录地址">
      </el-table-column>
      <el-table-column
        prop="lastTime"
        header-align="center"
        align="center"
        label="最后登录时间">
      </el-table-column>
      <el-table-column
        prop="lastAccount"
        header-align="center"
        align="center"
        label="最后登录账户">
      </el-table-column>
      <el-table-column
        prop="lastWxname"
        header-align="center"
        align="center"
        label="最后登录微信名称">
      </el-table-column>
      <el-table-column
        prop="lastPhone"
        header-align="center"
        align="center"
        label="最后登录手机">
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
  import AddOrUpdate from './wxsstock-add-or-update'
  export default {
    data () {
      return {
        dataForm: {
          dates:[]
        },
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListLoading: false,
        dataListSelections: [],
        addOrUpdateVisible: false
      }
    },
    components: {
      AddOrUpdate
    },
    activated () {
      this.refresh()
    },
    methods: {
      refresh(){
        this.pageIndex = 1
        this.getDataList()
      },
      // 获取数据列表
      getDataList () {
        this.dataListLoading = true
        console.log(this.dataForm)
        this.$http({
          url: this.$http.adornUrl('/wxs/wxsstock/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': this.pageIndex,
            'limit': this.pageSize,
            'startDate': this.dataForm.dates[0],
            'endDate': this.dataForm.dates[1],
            'customerName': this.dataForm.customerName,
            'deviceName': this.dataForm.deviceName,
            'deviceCode': this.dataForm.deviceCode,
            'remark': this.dataForm.remark,
            'lastAddr': this.dataForm.lastAddr,
            'lastPhone': this.dataForm.lastPhone,
            'state': this.dataForm.state,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.dataList = data.page.list
            this.totalPage = data.page.totalCount
          } else {
            this.dataList = []
            this.totalPage = 0
          }
          this.dataListLoading = false
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
      // 新增 / 修改
      addOrUpdateHandle (id) {
        this.addOrUpdateVisible = true
        this.$nextTick(() => {
          this.$refs.addOrUpdate.init(id)
        })
      },
      // 删除
      deleteHandle (id) {
        var ids = id ? [id] : this.dataListSelections.map(item => {
          return item.id
        })
        this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http({
            url: this.$http.adornUrl('/wxs/wxsstock/delete'),
            method: 'post',
            data: this.$http.adornData(ids, false)
          }).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.getDataList()
                }
              })
            } else {
              this.$message.error(data.msg)
            }
          })
        })
      }
    }
  }
</script>
